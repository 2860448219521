import http from '../../../../../../libs/http'
import calidad from '../../../../../../config/calidad'
import { useStore } from '@/store'
// export default (param) => http.get(`${calidad.host}/carpetas`, param)
export default class CarpetasServices {
  getMapa (param) {
    return http.get(`${calidad.host}/mapa-procesos/home`, {
      params: {
        ...param
      }
    })
  }

  getMapaProcesos (param) {
    return http.get(`${calidad.host}/mapa-procesos`, {
      params: {
        ...param
      }
    })
  }

  getMapaProceso (param) {
    return http.get(`${calidad.host}/mapa-procesos/${param}`, {
    })
  }

  getprocesos (param) {
    return http.get(`${calidad.host}/mapa-procesos`, {
      params: {
        ...param
      }
    })
  }

  getcarpetas (param) {
    return http.get(`${calidad.host}/mapa-procesos`, {
      params: {
        ...param
      }
    })
  }

  getdocumentos (param) {
    return http.get(`${calidad.host}/mapa-procesos`, {
      params: {
        ...param
      }
    })
  }

  getArchivoPath (id) {
    return http.get(`${calidad.host}/archivos/get-path/${id}`, {
    })
  }

  getArchivo (id, version) {
    return http.get(`${calidad.host}/archivos`, {
      params: {
        padre: id,
        version
      }
    })
  }

  getAreas () {
    return http.get(`${calidad.host}/solicitudes/get-areas`, {
    })
  }

  getSolicitudes (param) {
    return http.get(`${calidad.host}/solicitudes/paginate`, {
      params: {
        estadoId: param.estadoId
      }
    })
  }

  getCodigos (params) {
    return http.get(`${calidad.host}/codigos/get-cod`, {
      params: {
        codigo: params
      }
    })
  }

  getInactivos (param) {
    return http.get(`${calidad.host}/mapa-procesos/inactivos`, {
      params: {
        ...param
      }
    })
  }

  viewPdf (data) {
    const { path, nombre, ext } = data
    var tipoExt = ext === 'pdf' ? 'pdf' : 'xlsx'

    const store = useStore()
    return new Promise((resolve) => {
      const baseURL = calidad.host
      const url = `${baseURL}/archivos/visualizar`
      const token = store.getters['auth/getToken']
      const res = http.getUri({ url, params: { path, tipo: tipoExt, nombre, token } })
      resolve(res)
    })
  }
}
