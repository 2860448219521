<template>
  <div
    v-show="displayViewPdf"
    class="displayViewPdf">
    <div class="view_pdf_accion flex justify-end items-center">
      <Button
        icon="pi pi-times"
        v-tooltip.top="`Cerrar`"
        @click="displayViewPdf=false"
        class="p-button-rounded mr-2"
        style="width: 2rem; height: 2rem"
      />
    </div>
    <embed
      :src="`data:application/pdf;base64,${pathBase64}#zoom=100&bookmarks=1`"
      frameborder="0"
      style="width: 100%; height: 95%; border: none; z-index: 0; position: relative; top: 5%;"
    />
  </div>

  <Dialog
    v-model:visible="modalSolicitudes"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <template #header>
      <div class="flex justify-center items-center w-full gap-2">
        <i class="pi pi-eye font-bold"/>
        <span class="font-bold text-2xl">Ver Solicitud</span>
      </div>
    </template>

    <section class="flex gap-4">
      <template class="flex flex-col w-1/5">
        <div>
          <span class="font-bold text-sm">Ruta:</span>
          <ul class="wtree" v-if="dataModal.rutaArray[0]">
            <li>
              <span class="text-sm">{{ dataModal.rutaArray[0] }}</span>
              <ul v-if="dataModal.rutaArray[1]">
                <li>
                  <span class="text-sm">{{ dataModal.rutaArray[1] }}</span>
                  <ul v-if="dataModal.rutaArray[2]">
                    <li>
                      <span class="text-sm">{{ dataModal.rutaArray[2] }}</span>
                      <ul v-if="dataModal.rutaArray[3]">
                        <li>
                          <span class="text-sm">{{ dataModal.rutaArray[3] }}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="flex flex-col mt-6">
          <span class="font-bold text-sm">Areas:</span>
          <div class="flex gap-2 flex-wrap">
            <template v-for="(row, key) in dataModal.areasSolici" :key="key">
              <span class="border p-1 rounded-xl pl-2.5 pr-2.5 text-xs">{{ row.areas.name }}</span>
            </template>
          </div>
        </div>
      </template>
      <template class="flex flex-col gap-6 w-4/5 pl-8 pr-8">
        <div class="flex gap-4 justify-between">
          <div class="flex flex-col gap-2 w-1/6">
            <span class="font-bold text-sm">Tipo:</span>
            <span class="text-sm">{{ tipoSolicitud[dataModal.tipoId] }}</span>
          </div>
          <div class="flex flex-col gap-2 w-4/6">
            <span class="font-bold text-sm">Creado Por:</span>
            <span class="text-sm">{{ dataModal.created.fullName }}</span>
          </div>
          <div class="flex flex-col gap-2 w-1/6">
            <span class="font-bold text-sm">Estado:</span>
            <span v-if="estadoSolicitud[dataModal.estadoId]"
              class="flex justify-center rounded-md font-bold  text-sm"
              :style="`background-color: ${estadoSolicitud[dataModal.estadoId].bg}; color: ${estadoSolicitud[dataModal.estadoId].color}`"
            >{{ estadoSolicitud[dataModal.estadoId].nombre }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <span class="font-bold text-sm">Titulo:</span>
          <span class="text-sm">{{ dataModal.asunto }}</span>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <span class="font-bold text-sm">Solicitud:</span>
          <span class="text-justify text-sm">{{ dataModal.solicitud }}</span>
        </div>
        <div v-if="dataModal.path" class="flex flex-col gap-2 w-full">
          <span class="font-bold text-sm">Documentación:</span>

          <div class="flex w-2/3 items-center justify-between hover:bg-gray-100 cursor-pointer p-2 rounded-md"
               style="transition: 0.3s"
               @click="descargarDoc(dataModal)"
          >
            <div class="flex gap-4 items-center">
              <i :class="`pi pi-file${dataModal.ext == 'xlsx' ? '-excel text-green-400' : dataModal.ext == 'txt' ? '-pdf text-red-400' : ''} my-auto mx-2`"></i>
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="#B30B00"
                      d="M6.5 11a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-.166h.334a1.167 1.167 0 0 0 0-2.334H6.5Zm.834 1.334H7V12h.334a.167.167 0 0 1 0 .334ZM12 11.499a.5.5 0 0 1 .5-.499h.998a.5.5 0 1 1 0 1H13v.335h.498a.5.5 0 1 1 0 1H13v.164a.5.5 0 1 1-1 .002v-.667l.002-1.335ZM9.5 11a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h.502a1.5 1.5 0 0 0 0-3H9.5Zm.5 2v-1h.002a.5.5 0 0 1 0 1H10Zm.002-6.5V2H5.5A1.5 1.5 0 0 0 4 3.5v5.582a1.5 1.5 0 0 0-1 1.414v4.003a1.5 1.5 0 0 0 1 1.414v.587A1.5 1.5 0 0 0 5.5 18h9a1.5 1.5 0 0 0 1.5-1.5v-.587a1.5 1.5 0 0 0 .998-1.414v-4.003A1.5 1.5 0 0 0 16 9.082V8h-4.5A1.5 1.5 0 0 1 10 6.5Zm-5.5 3.496h10.997a.5.5 0 0 1 .5.5v4.003a.5.5 0 0 1-.5.5H4.502a.5.5 0 0 1-.5-.5v-4.003a.5.5 0 0 1 .5-.5ZM11 6.5V2.25L15.75 7H11.5a.5.5 0 0 1-.5-.5Z"/>
              </svg> -->
              <span class="text-sm">{{ dataModal.nombreArchivo.trim() }}</span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 class="text-gray-500 hover:text-blue-800 ">
              <path fill="currentColor"
                    d="M12 15.575q-.2 0-.375-.063q-.175-.062-.325-.212l-3.6-3.6q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.712-.288q.438-.012.713.263L11 12.15V5q0-.425.288-.713Q11.575 4 12 4t.713.287Q13 4.575 13 5v7.15l1.875-1.875q.275-.275.713-.263q.437.013.712.288q.275.275.275.7q0 .425-.275.7l-3.6 3.6q-.15.15-.325.212q-.175.063-.375.063ZM6 20q-.825 0-1.412-.587Q4 18.825 4 18v-2q0-.425.287-.713Q4.575 15 5 15t.713.287Q6 15.575 6 16v2h12v-2q0-.425.288-.713Q18.575 15 19 15t.712.287Q20 15.575 20 16v2q0 .825-.587 1.413Q18.825 20 18 20Z"/>
            </svg>
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full"
             v-if="$can('pharmasan.gestion.calidad.mapa-procesos.responder-solicitudes') || ($can('pharmasan.gestion.calidad.mapa-procesos.administrador') && dataModal.estadoId === 3) && !dataModal.respuesta && !(dataModal.estadoId === 6)">
          <span class="font-bold text-sm">Observaciones de respuesta:</span>
          <Textarea class="text-sm" v-model="respuesta" rows="2" autoResize/>
        </div>
        <div class="flex flex-col gap-2 w-full" v-if="dataModal.respuesta">
          <span class="font-bold text-sm">Respuesta:</span>
          <span class="text-sm">{{ dataModal.respuesta }}</span>
        </div>

        <div v-if="dataModal.estadoId === 4 || dataModal.estadoId === 5" class="flex flex-col gap-2 w-full">
          <span class="font-bold text-sm">Motivo Rechazo:</span>
          <template v-for="(row, key) in dataModal.respuestas" :key="key">
            <template v-if="row.respuesta">
              <div class="rounded p-2 text-sm" style="background-color: #F8F8F8">{{ row.respuesta }}</div>
              <div class="flex justify-end">
                <span class="text-xs font-bold">{{ row.created.fullName }} ({{ row.areas.name }})</span>
              </div>
            </template>
          </template>
        </div>
      </template>
    </section>
    <div class="flex justify-center gap-4 mt-6" v-if="dataModal.estadoId === 4">
      <Button
        label="Anular"
        icon="pi pi-times"
        class="p-button-outlined"
        @click="anular(dataModal)"
      />
      <Button
        label="Corregir"
        icon="pi pi-pencil"
        @click="corregir(dataModal)"
      />
    </div>

    <template #footer
              v-if="$can('pharmasan.gestion.calidad.mapa-procesos.responder-solicitudes') || ($can('pharmasan.gestion.calidad.mapa-procesos.administrador') && dataModal.estadoId === 3) && !dataModal.respuesta && !(dataModal.estadoId === 6)">
      <div class="flex justify-center" v-if="$can('pharmasan.gestion.calidad.mapa-procesos.responder-solicitudes') && dataModal.estadoId !== 4">
        <Button v-if="dataModal.estadoId !== 3 && estadoSolicitud[dataModal.estadoId].nombre != 'PUBLICADO'" label="Rechazar" icon="pi pi-times" class="p-button-outlined" @click="responder(2)"/>
        <Button v-if="dataModal.estadoId !== 3 && estadoSolicitud[dataModal.estadoId].nombre != 'PUBLICADO'" label="Aprobar" icon="pi pi-check" @click="responder(1)"/>
      </div>
      <div class="flex justify-center" v-if="$can('pharmasan.gestion.calidad.mapa-procesos.administrador')">
        <Button v-if="dataModal.estadoId === 3" label="Rechazar" icon="pi pi-times" class="p-button-outlined"
                @click="publicar(false)"/>
        <Button
          v-if="dataModal.estadoId === 3"
          label="Publicar"
          icon="pi pi-check"
          @click="publicar(true)"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
import store from './store'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

export default {
  name: 'modalVerResponder',
  setup (props, { emit }) {
    // Computed
    const getRespuestaId = computed(() => store.getters.getRespuestaId)
    const dataModal = computed(() => store.getters.getSolicitud)

    // Referencias
    const router = useRouter()

    // Services
    const _modalSolicitudesService = new ModalSolicitudesService()

    // Data
    const modalSolicitudes = ref(false)
    const displayViewPdf = ref(false)
    const pathBase64 = ref(null)
    const respuesta = ref('')
    const tipoSolicitud = ref({
      1: 'Creación',
      2: 'Modificación',
      3: 'Inactivacion'
    })
    const estadoSolicitud = ref({})

    // Computed

    // Methods
    const openModal = (id) => {
      respuesta.value = ''
      store.dispatch('getSolicitud', id).then(() => {
        pathBase64.value = dataModal.value.pathBase64
        dataModal.value.rutaArray = dataModal.value.ruta.split(' > ')
        modalSolicitudes.value = true
        sacarExt()
      })
    }
    const closeModal = () => {
      modalSolicitudes.value = false
      // respuesta.value = ''
    }
    const descargarDoc = async ({ path, id }) => {
      if (dataModal.value.ext === 'xlsx') {
        const url = _modalSolicitudesService.getUri({ path: dataModal.value.path, tipo: dataModal.value.ext, nombre: dataModal.value.nombreArchivo })
        window.open(url, '_blank')
      } else {
        displayViewPdf.value = true
      }
    }
    const responder = (tipo) => {
      if (tipo === 2) {
        Swal.fire({
          title: '¿Está seguro?',
          text: 'Esta acción no se puede deshacer',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, estoy seguro'
        }).then(async (result) => {
          if (result.isConfirmed) {
            _modalSolicitudesService.responderSolicitud({
              id: dataModal.value.id,
              respuesta: respuesta.value,
              aprobado: tipo === 1, // 2 Aprobado, 3 Rechazado
              respuesta_id: getRespuestaId.value
            }).then(() => {
              store.dispatch('getSolicitudes', {
                limit: 10,
                offset: 0
              })
              closeModal()
            })
          }
        })
      } else {
        _modalSolicitudesService.responderSolicitud({
          id: dataModal.value.id,
          respuesta: respuesta.value,
          aprobado: tipo === 1, // 2 Aprobado, 3 Rechazado
          respuesta_id: getRespuestaId.value
        }).then(() => {
          store.dispatch('getSolicitudes', {
            limit: 10,
            offset: 0
          })
          closeModal()
        })
      }
    }

    const publicar = async (estado) => {
      if (estado) {
        const rutaTemp = dataModal.value.ruta.split('>')
        if (rutaTemp.length) {
          await store.dispatch('actionModalPublicar')
          closeModal()
          console.log('publicar modalresponder---', respuesta.value)
          emit('openModalPublicar', {
            ruta: dataModal.value.ruta,
            estado,
            respuesta: respuesta.value,
            solicitudId: dataModal.value.id,
            areas: dataModal.value.areasSolici,
            tipo: dataModal.value.ext,
            mapaProcesos: dataModal.value.mapaProcesos,
            path: dataModal.value.path
          })
        } else {
          _modalSolicitudesService.publicarSolicitud({
            ruta: dataModal.value.ruta,
            estado,
            respuesta: respuesta.value,
            solicitudId: dataModal.value.id
          }).then(() => {
            Swal.fire({
              icon: 'success',
              title: '<span style="line-height: normal;">Se publicó el documento correctamente</span>'
            }).then(() => {
                store.dispatch('getSolicitudes', {
                limit: 10,
                offset: 0
              })
            })
          })
        }
      } else {
        if (respuesta.value === '') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Debe ingresar una respuesta'
          })
        }
        _modalSolicitudesService.publicarSolicitud({
          estado: false,
          respuesta: respuesta.value,
          solicitudId: dataModal.value.id
        }).then(() => {
          closeModal()
          store.dispatch('getSolicitudes', {
            limit: 10,
            offset: 0
          })
        })
      }
    }
    const corregir = ({ id }) => {
      router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.editar-solicitudes', params: { id } })
    }
    const anular = ({ id }) => {
      Swal.fire({
        title: '¿Está seguro?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, estoy seguro'
      }).then(async (result) => {
        if (result.isConfirmed) {
          _modalSolicitudesService.anularSolicitud(id).then(() => {
            store.dispatch('getSolicitudes', {
              limit: 10,
              offset: 0
            })
            closeModal()
          })
        }
      })
    }
    const sacarExt = () => {
      var filename = dataModal.value.path.split('.')
      dataModal.value.ext = filename[filename.length - 1]
    }

    // Watchers

    // OnMounted
    onMounted(() => {
      _modalSolicitudesService.getEstados().then(({ data }) => {
        estadoSolicitud.value = data.reduce((obj, item) => {
          obj[item.id] = item
          return obj
        }, {})
      })
    })

    return {
      modalSolicitudes,
      openModal,
      closeModal,
      tipoSolicitud,
      estadoSolicitud,
      dataModal,
      descargarDoc,
      responder,
      respuesta,
      displayViewPdf,
      pathBase64,
      publicar,
      corregir,
      anular
    }
  }
}
</script>

<style scoped>
.hover\:bg-gray-100 .hover\:text-blue-800 {
  display: none;
}

.hover\:bg-gray-100:hover .hover\:text-blue-800 {
  display: flex;
  transition: 0.3s;
}
</style>

<style lang="scss" scoped>

.displayViewPdf {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  // opacity: 0.9;
  background: #525659;

  .view_pdf_accion {
    position: fixed;
    top: 0;
    left: 0;
    color: #fff;
    width: 100%;
    height: 5%;
    padding: 0.75rem;
    z-index: 9999;

    i {
      font-size: 1.5rem;
    }
  }
}

$border: #ddd;
$border-hover: #aaa;
$bg-hover: #eee;
$text: #888;
$text-hover: #000;

ul {
  margin-left: 5px;
}

.wtree {
  li {
    list-style-type: none;
    margin: 0 0 5px 10px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -5px;
      border-left: 1px solid $border;
      border-bottom: 1px solid $border;
      width: 5px;
      height: 10px;
    }

    &:after {
      position: absolute;
      content: "";
      top: 10px;
      left: -5px;
      border-left: 1px solid $border;
      border-top: 1px solid $border;
      width: 5px;
      height: 100%;
    }

    &:last-child:after {
      display: none;
    }

    span {
      display: block;
      //border: 1px solid $border;
      padding: 1px;
      color: $text;
      text-decoration: none;
    }
  }
}

</style>
