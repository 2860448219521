<template>
  <Dialog
    v-model:visible="modalPublicar"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '40vw'}"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-center items-center w-full gap-2">
        <i class="pi pi-book font-bold"/>
        <span class="font-bold text-2xl">Publicar Solicitud</span>
      </div>
    </template>
    <section class="flex flex-col gap-4 w-full p-4 items-center">

      <div class="flex flex-col gap-4 w-1/2 items-center">

        <div class="flex flex-col gap-2">
          <label>Ubicación del Documento o Carpeta</label>
          <TreeSelect
            v-model="rutaSelected"
            :options="mapaProcesos"
            placeholder="Seleccione el documento o carpeta"
            class="md:w-20rem w-full p-inputtext-xs" />
          <span class="text-xs text-red-500" v-if="errors.rutaSelected">{{ errors.rutaSelected }}</span>
        </div>

        <div class="mb-5 flex flex-col">
          <label class="mb-2">Documento</label>
          <AutoComplete class="autocomplete-sm w-full" v-model="values.cod" field="codigo" optionValue="id" forceSelection dropdown
                  :suggestions="ListCodigos" @complete="searchCod" @item-select="changeCod"/>
          <!-- <span class="text-xs text-red-600 ">El código es requerido</span> -->
        </div>

      </div>
    </section>
    <template #footer>
      <div class="flex justify-center">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-outlined"
          @click="actionModal"
        />
        <Button
          label="Publicar"
          icon="pi pi-check"
          @click="publicar"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { onMounted, ref } from 'vue'
import store from '../store'
import GetServices from '../services/get.service'
import PostService from '../services/post.service'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useField, useForm } from 'vee-validate'
import TreeSelect from 'primevue/treeselect'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'

export default {
  name: 'modalPublicar',
  components: {
    TreeSelect
  },
  setup () {
// const props = defineProps({
//   mapa: Number
// })
// const emit = defineEmits(emitsDef);
    // Computed
    // const modalPublicar = computed(() => store.getters.getModalPublicar)
    const modalPublicar = ref()

    // Referencias
    const mapaProcesos = ref([])
    const ListCodigos = ref([])
    const rutaSelected = ref({})
    const _modalSolicitudesService = new ModalSolicitudesService()
    const _Get = ref(new GetServices())
    const _Post = ref(new PostService())
    // const areas = ref([])
    const dataModal = ref({})
    const dataSolicitud = ref(null)

    // Services

    // Data
    const obj = ref({
      mapa: 0,
      documento: 0
    })
    const schema = yup.object({
      // areas: yup.array().nullable().required('El area es requerida'),
      // tipoProceso: yup.string().nullable().required('El tipo de proceso es requerido').label(''),
      // proceso: yup.string().nullable().required('El proceso es requerido').label(''),
      // carpeta: yup.string().nullable().required('La carpeta es requerida').label(''),
      // codigo: yup.string().nullable().required('El código es requerido').label(''),
      // documento: yup.string().nullable().required('El documento es requerido').label('')
    })
    const { errors, values, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    // useField('areas', null, { initialValue: null })
    // useField('tipoProceso', null, { initialValue: null })
    useField('mapa', null, { initialValue: null })
    useField('cod', null, { initialValue: null })

    // Computed

    // Methods
    const actionModal = () => {
      modalPublicar.value = false
      // store.dispatch('actionModalPublicar')
    }

    const searchCod = (event) => {
      _Get.value.getCodigos(event.query).then(({ data }) => {
        ListCodigos.value = data
      })
    }

    const getMapa = (mapaProc, datosSol) => {
      // rutaSelected.value = mapaProc
      dataSolicitud.value = datosSol
      rutaSelected.value[mapaProc] = true
      // values.cod = {}
      // values.cod = rutaSelected.value
      // console.log('getMapa---rutaSelected---', values)
      _modalSolicitudesService.getMapaProcesos({ mapa: mapaProc }).then((res) => {
        console.log('lo que llega de getMapa', res)
        mapaProcesos.value = res.data.map(n1 => {
          return {
            key: n1.id,
            label: n1.nombre,
            data: n1.nombre,
            icon: 'pi pi-fw pi-cog',
            children: n1.hijos.map(n2 => {
              return {
                key: n2.id,
                label: n2.nombre,
                data: n2.nombre,
                icon: 'pi pi-fw pi-cog',
                children: n2.hijos.map(n3 => {
                  return {
                    key: n3.id,
                    label: n3.nombre,
                    data: n3.nombre,
                    children: !n3.hijos ? null : n3.hijos.map(n4 => {
                      return {
                        key: n4.id,
                        label: n4.nombre,
                        data: n4.nombre,
                        children: !n4.hijos ? null : n4.hijos.map(n5 => {
                        return {
                          key: n5.id,
                          label: n5.nombre,
                          data: n5.nombre
                        }
                      })
                      }
                    })
                  }
                })
              }
            })
          }
        })
        // console.log('---getMapa---', mapaProcesos.value)
        modalPublicar.value = true
      })
    }

    const concatenarNombres = (mapa) => {
      if (mapa) {
          return mapa.label + ' > ' + mapa.children.map(concatenarNombres).join(' > ')
      } else {
          return mapa.label
      }
    }

    const changeCod = (event) => {
      console.log('changeCod---', event)
      if (event.value) {
        dataModal.value.mapa = event.value.mapaId
      }
    }

    const crearArchivo = () => {
      console.log('dataSolicitud.value----', dataSolicitud.value)
      // console.log('crearArchivo--', newArchivo.value)
      var archivo = {
        // nombre: newArchivo.value.nombre,
        path: dataSolicitud.value.path,
        tipo: dataSolicitud.value.tipo,
        padre: dataModal.value.mapa,
        solicitudId: dataModal.value.solicitudId
      }
      _Post.value.crearArchivo(archivo).then(({ data }) => {
        console.log('lo que retorna crear archivo----', data)
        Swal.fire({
          icon: 'success',
          title: '<span style="line-height: normal;">Se publicó el documento correctamente</span>'
        }).then(result => {
          actionModal()
          handleReset()
          store.dispatch('getSolicitudes', {
            limit: 10,
            offset: 0
          })
        })
      }).catch(err => {
        console.log(err.message)
      })
    }

    const publicar = handleSubmit((values) => {
      // values.mapa = rutaSelected.value
      console.log('-----publicar----', dataModal.value, dataSolicitud.value)
      dataModal.value.respuesta = dataSolicitud.value.respuesta
      dataModal.value.estado = dataSolicitud.value.estado
      dataModal.value.solicitudId = dataSolicitud.value.solicitudId
      dataModal.value.tipo = dataSolicitud.value.tipo

      if (rutaSelected.value) {
        const claveNumerica = Object.keys(rutaSelected.value).find(key => typeof parseInt(key) === 'number')
        if (claveNumerica !== undefined) {
          values.mapa = parseInt(claveNumerica)
          // delete values.rutaSelected
        }
      }
      var objpublicar = {
        ruta: concatenarNombres(mapaProcesos.value[0]),
        estado: dataModal.value.estado,
        respuesta: dataModal.value.respuesta,
        solicitudId: dataModal.value.solicitudId,
        tipo: dataModal.value.tipo,
        mapa: dataModal.value.mapa,
        padre: values.mapa
        // areas: values.areas
      }
      console.log('---publicar---', objpublicar)
      _modalSolicitudesService.publicarSolicitud(objpublicar).then(() => {
        crearArchivo()
      })
    })

    // Watchers

    // OnMounted
    onMounted(() => {
      // _modalSolicitudesService.getAreasList().then(({ data }) => {
      //   areas.value = data
      //   // values.areas = getInfoPublicar.value.areas.map(area => area.areaId)
      // })
      // getMapa()
    })

    return {
      rutaSelected,
      mapaProcesos,
      ListCodigos,
      modalPublicar,
      errors,
      values,
      obj,
      changeCod,
      getMapa,
      actionModal,
      publicar,
      searchCod
    }
  }
}
</script>
